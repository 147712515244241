import React, { useContext, useState } from "react"
import "../styles/gallery-picture.css"
import { galleryPictureContent } from "../content/gallery-picture"
import { SettingsContext } from "../contexts/settings"
import '../compatibility-wrapper/mediaelement'
import { imageUrl } from "../../utils/path"
import { translate } from "../../utils/language"
import ReactPlayer from 'react-player'

const GalleryPicture = ({media, language}) => {
    const [settings, ] = useContext(SettingsContext)
    const content = galleryPictureContent[settings.language]
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0) 

    const nextMedia = () => {
        if (media.length === currentMediaIndex + 1) {
            setCurrentMediaIndex(0)
            return
        }
        setCurrentMediaIndex(currentMediaIndex + 1)
    }

    const previousMedia = () => {
        if (currentMediaIndex === 0) {
            setCurrentMediaIndex(media.length-1)
            return
        }
        setCurrentMediaIndex(currentMediaIndex - 1)
    }
    const currentMedia = media[currentMediaIndex]
    return (
        <div className="gallery-container">
            <div className="gallery">
                {
                media.length > 1 ?
                <button className="link-gallery" onClick={previousMedia}><p className="arrow-gallery link-gallery" aria-label={content.previousMedia}>{content.left}</p></button> :
                null
                }
                <div className="picture-gallery">
                    {
                        currentMedia.__typename === "Strapi_ComponentDefaultBildMitMehrsprachigemAltText" &&
                        <img className="pic-big" src={imageUrl(currentMedia.Bild.url)} alt={translate(language, currentMedia.Deutsch, currentMedia.Englisch)}/>
                    }
                        
                    {
                        currentMedia.__typename === "Strapi_ComponentDefaultVideo" &&
                        <video controls className="pic-big"
                            src={imageUrl(currentMedia.VideoDatei.url)}>
                        </video>
                    }
                    {
                        currentMedia.__typename === "Strapi_ComponentDefaultExternerInhalt" &&
                        <div className="pic-big">
                            <ReactPlayer className="video-gallery" url={currentMedia.URL} controls={true}/>
                        </div>
                    }
                    


                    <div className="mint-line-gallery"></div>
                </div>
                {
                media.length > 1 ?
                <button className="link-gallery" onClick={nextMedia}><p className="arrow-gallery" aria-label={content.nextMedia}>{content.right}</p></button> :
                null
                }
                </div>
            
        </div>
    )
}
export default GalleryPicture
const galleryPictureContent = {
    german: {
        left: " < ",
        right: " > ",
        previousMedia: "zum vorherigen Bild",
        nextMedia: "zum nächsten Bild",
    },
    english: {
        left: " < ",
        right: " > ",
        previousMedia: "to the previous picture",
        nextMedia: "to the next picture",
    }
}
export {galleryPictureContent}
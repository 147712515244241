import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import Template from "./template"
import "../styles/projects-page.css"
import { SettingsContext } from "../contexts/settings"
import { translate } from "../../utils/language"
import { imageUrl, fileUrl } from "../../utils/path"
import { formatDate } from "../../utils/formatdate"
import { renderMarkdown, renderMarkdownTitel } from "../../utils/rendermarkdown"
import GalleryPicture from "./gallery-picture"

function date(l, tag) {
  const localisedText = formatDate(l, tag.Datum)


  if (tag.Nummer) {
    return localisedText
  }

  return localisedText;
}

function showMonth (l, tag, tag_ende) {
	const  dateWithMonth = formatDate(l, tag.Datum)


	if (tag.Datum === tag_ende.Datum) {
		return null
	} else {
		return `${dateWithMonth} - `
	}
}

const ProjectsPage = ({ data }) => {
  const [settings,] = useContext(SettingsContext)
  const l = settings.language
  const v = data.strapi.veranstaltung
  return (
    <Template currentPage="projects">
      <section className="end-of-site">

        <h1>
          <Link class="back-arrow" to="/projekte">{translate(l, "Projekte << ", "Projects << ")}</Link>
          {renderMarkdownTitel(translate(l, v.Titel_Deutsch, v.Titel_Englisch))}
        </h1>

        <div className="merkbox">
          {
            v.tag &&
            <div className="merkbox-element">
              <img className="icon-programm" src={settings.isHighContrast ? "/images/icons/Kalender_schwarz.png" : "/images/icons/Kalender.svg"} alt={translate(l, "Icon Kalender", "icon calender")} />
              {showMonth(l, v.tag, v.tag_ende)}
						  {date(l, v.tag_ende)}
            </div>
          }
          {
            v.Uhrzeit &&
            <div className="merkbox-element">
              <img className="icon-programm" src={settings.isHighContrast ? "/images/icons/Uhr_schwarz.png" : "/images/icons/Uhr.svg"} alt={translate(l, "Icon Zeit", "icon time")} />
              {v.Uhrzeit}{translate(l, " Uhr", "")}
            </div>
          }
          {
            v.ort &&
            <div className="merkbox-element">
              <img className="icon-programm" src={settings.isHighContrast ? imageUrl(v.ort.Icon_kontrastreich.url) : imageUrl(v.ort.Icon.url)} alt={translate(l, "Icon Location", "icon location")} />
              {translate(l, v.ort.Deutsch, v.ort.Englisch)}
            </div>
          }
          {
            v.veranstaltungsformat &&
            <div className="merkbox-element">
              <img className="icon-programm" src={settings.isHighContrast ? imageUrl(v.veranstaltungsformat.Symbol_kontrastreich.url) : imageUrl(v.veranstaltungsformat.Symbol.url)} alt={translate(l, "Icon Veranstaltungsformat", "icon event type")} />
              {translate(l, v.veranstaltungsformat.Deutsch, v.veranstaltungsformat.Englisch)}
            </div>
          }
          {
            v.sprachen && v.sprachen.length !== 0 &&
            v.sprachen.map(sprache => {
              return <div className="merkbox-element">
                <img className="icon-programm" src={settings.isHighContrast ? "/images/icons/Sprache_schwarz.png" : "/images/icons/Sprache.svg"} alt={translate(l, "Icon Sprache", "icon language")}/>
                {translate(l, sprache.Deutsch, sprache.Englisch)}              
              </div>

            })}
            { v.barrierefreiheits_eigenschaftens.map(eigenschaften => {	
              return <div className="merkbox-element">
                <img className="icon-barrierefreiheit" src={settings.isHighContrast ? imageUrl(eigenschaften.Icon_dunkel.url) : imageUrl(eigenschaften.Icon.url)} alt={translate(l, "Icon Barriereeigenschaft", "icon accessibility feature")}/>
              </div>
            })}

        </div>
        <div className="mint-line"></div>
        <div className="information-container">
          <div className="project-text-button">
          {
            v.Beschreibung &&
            <div className="information">
              <p>{renderMarkdown(translate(l, v.Beschreibung.Deutsch, v.Beschreibung.Englisch))}</p>
            </div>
          }
          {
              v.TicketURL &&
              <button className="button-tickets" aria-label={translate(l, "Ticket Seite", "ticket page")} >
                <a className="tickets-link" href={v.TicketURL} target="_blank" rel="noopener noreferrer">
                  <span className="black-button-tickets">TICKETS</span>
                  <div className="mint-line"></div>
                </a>
              </button>
            }
          </div>
          <div className="boxes">
            
            {
              v.GutZuWissen &&
              <div className="good-to-know-container">
                <div><h2 className="box-heading">{translate(l, "Gut zu wissen", "Good to know")}</h2></div>

                {
                  v.GutZuWissen &&
                  <div><p>{renderMarkdown(translate(l, v.GutZuWissen.Deutsch, v.GutZuWissen.Englisch))}</p></div>
                }
                {v.barrierefreiheits_eigenschaftens.map(eigenschaften => {
                  return <div className="gut-zu-wissen-barriere-info">
                    <img className="gut-zu-wissen-icon-barrierefreiheit" src={settings.isHighContrast ? imageUrl(eigenschaften.Icon_dunkel.url) : imageUrl(eigenschaften.Icon.url)} alt={translate(l, "Icon Barriereeigenschaft", "icon accessibility feature")} />
                    <p>{translate(l, eigenschaften.Deutsch, eigenschaften.Englisch)}</p>
                  </div>
                })}

              </div>
            }
          </div>
          
        </div>
        {
          v.Gallerie && v.Gallerie.length !== 0 &&
          <div>
            <h2 className="centered">{translate(l, "Galerie", "Gallery")}</h2>
            <GalleryPicture media={v.Gallerie} ></GalleryPicture>
          </div>
        }

        {v.Downloads && v.Downloads.length !== 0 &&
          <h2>{translate(l, "Projekt Downloads", "Project Downloads")}</h2>
        }

        {v.Downloads.map(downloads => {
          return <div>
            <a className="link" href={fileUrl(translate(l, downloads.Download_deutsch.url, downloads.Download_englisch.url))} target="_blank" rel="noopener noreferrer">
              {translate(l, downloads.Titel_deutsch, downloads.Titel_englisch)}
            </a>
          </div>
        })}

        {v.Bios && v.Bios.length !== 0 && <h2>Team</h2>}
        {v.Bios.map(person => {
          return <div>
            <div className="pic-text-projects">
              {
                person.bild && person.bild.Bild &&
                <img className="pic-projects" src={imageUrl(person.bild.Bild.url)} alt={translate(l, person.bild.Deutsch, person.bild.Englisch)} />
              }
              {
                v.Bios &&
                <div className="text-projects">
                  <h3 className="no-indent-project">{translate(l, person.Titel_deutsch, person.Titel_englisch)}</h3>
                  <p>{renderMarkdown(translate(l, person.Deutsch, person.Englisch))}</p>
                </div>
              }
            </div>
          </div>

        })}
        {
            v.credits &&
            <div>
                <p>{renderMarkdown(translate(l, v.credits.Deutsch, v.credits.Englisch))}</p>
            </div>
          }

      </section>
    </Template>
  )
}

export default ProjectsPage

export const query = graphql`
  query($id: ID!) {
    strapi {
        veranstaltung(id: $id) {
          Beschreibung {
            Deutsch
            Englisch
          }
          TicketURL
          Downloads {
            Download_deutsch {
              url
            }
            Link_deutsch
            Link_englisch
            Titel_deutsch
            Titel_englisch
            Download_englisch {
              url
            }
          }
          
          Titel_Deutsch
        Titel_Englisch
          Uhrzeit
          ort {
            Deutsch
            Englisch
            Icon {
              url
            }
			Icon_kontrastreich {
				url
			}
          }
          tag {
            Datum
            Nummer
          }
          tag_ende{
            Datum
          }
          veranstaltungsformat {
            Deutsch
            Englisch
            Symbol {
              url
            }
			Symbol_kontrastreich {
				url
			}
          }
          GutZuWissen {
            Deutsch
            Englisch
          }
          barrierefreiheits_eigenschaftens {
            Deutsch
            Englisch
            Icon {
              url
            }
            Icon_dunkel {
              url
            }
          }
          credits {
            Deutsch
            Englisch
          }
          Bios {
            bild {
              Bild {
                url
              }
              Deutsch
              Englisch
            }
            Deutsch
            Englisch
            Titel_deutsch
            Titel_englisch
          }
          bild {
            Bild {
              url
            }
            Deutsch
            Englisch
          }
          credits {
            Deutsch
            Englisch
          }
          sprachen {
            Deutsch
            Englisch
          }
          Gallerie {
            __typename
            ... on Strapi_ComponentDefaultVideo {
              VideoDatei {
                url
              }
            }
            ... on Strapi_ComponentDefaultBildMitMehrsprachigemAltText {
              Bild {
                url
              }
              Deutsch
              Englisch
            }
            ... on Strapi_ComponentDefaultExternerInhalt {
              URL
            }
          }
        }
      }
    }
`